import { sanitiseText } from '../helpers/sanitiseText';
import ExpanderRelative from './_generic/expander/ExpanderRelative';

const AdditionalContent = ({ additionalContentText }) => {
  const SEO_TEXT_MAX_HEIGHT = 180;
  const SEO_TEXT_MARGIN_BOTTOM = 20;

  return (
    <>
      <div className="additional-content container">
        <ExpanderRelative
          centeredButton
          marginBottom={SEO_TEXT_MARGIN_BOTTOM}
          maxHeight={SEO_TEXT_MAX_HEIGHT}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: sanitiseText(additionalContentText),
            }}
          />
        </ExpanderRelative>
      </div>
      <style jsx>{`
        .additional-content {
          margin-top: 10px;
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        }
      `}</style>
    </>
  );
};

export default AdditionalContent;
